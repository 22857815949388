import React from "react";
import {Col, Row} from "react-bootstrap";
import {Book, PencilSquare, BookFill} from "react-bootstrap-icons";
import {ExcelDateToJSDate} from "../../utils/functions";

const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"

export const AbstractTableHeaders =
        <thead>
        <tr>
            <th>View/Edit</th>
            <th>County</th>
            <th>Document</th>
            <th>Instrument</th>
            <th>Effective Date</th>
            <th>Filed Date</th>
            <th>Received Date</th>
            <th>Grantor</th>
            <th>Grantee</th>
            <th>Book-Page</th>
            <th>Document Number</th>
            <th>Description</th>
            <th>WOL</th>
            <th>Comments</th>
        </tr>
        </thead>
;


    export const AbTable = (props) => (
        props.docQueryResults.map((document, index) => {
            if (document.downloadURL === "") {
                document.downloadURL = `${storageURL}/Counties/${document.county}/${document.document}.pdf`
            }
let url = document.downloadURL;

    return (<tr key={index} >
        <td>
            <Row>
                <Col>
                    {document.downloadURL ?
                    <Book onClick={() => {
                        window.open(url)
                    }}
                    /> : <BookFill onClick={() => {
                            alert("Pdf not uploaded")
                        }}
                        />
                    }
                </Col>
                <Col>
                    <PencilSquare onClick={() => {
                        console.log(document)
                        props.setDocument(document)
                        props.handleShow()
                    }}
                    />
                </Col>
            </Row>
        </td>
        <td>{document.county}</td>
        <td>{document.document}</td>
        <td>{document.instrument}</td>
        <td>{ExcelDateToJSDate(document.effectiveDate)}</td>
        <td>{ExcelDateToJSDate(document.fileDate)}</td>
        <td>{ExcelDateToJSDate(document.receivedDate)}</td>
        <td>{document.grantor}</td>
        <td>{document.grantee}</td>
        <td>{document.book}-{document.page}</td>
        <td>{document.reqNumber}</td>
        <td>{document.description}</td>
        <td>{document.wol}</td>
        <td>{document.comments}</td>
    </tr>)
})
    );