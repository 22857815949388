import {computed, effect, signal} from "@preact/signals-react";

/* Database service imports */



export function createAppState() {




    /* ALERTBOX */

    const alertMessage = signal("")
    const alertSeverity = signal("")
    const alertProgress = signal(0)

effect(
    () => {
        if (alertProgress.value === 100 || alertProgress.value !== 0 || alertMessage.value !== "") {
            const timeId = setTimeout(() => {
                alertMessage.value = "";
                alertSeverity.value = ""
                alertProgress.value = 0
            }, 3000)

            return () => {
                clearTimeout(timeId)
            }
        }
    }
)

    /* IMPORT NOTIFICATIONS */

    const importStats = signal({"added": [], "updated": []})

    const showImportResults = signal(false)

    /* Document Table*/

    const tableRows = signal([])


    const addedRows = computed(() => {
        if (importStats.value.added.length > 0) {
            return importStats.value.added
        }
    })

    const updatedRows = computed(() => {
        if (importStats.value.updated.length > 0) {
            return importStats.value.updated
        }
    })

    /*SEARCH */

    const searchDocument = signal("")


        return {
            alertMessage,
            alertSeverity,
            alertProgress,
            importStats,
            showImportResults,
            tableRows,
            addedRows,
            updatedRows,
            searchDocument
        }
    }