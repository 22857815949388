import {Button, Col, Modal} from "react-bootstrap";
import React from "react";
import AddEditDocument from "../../pages/Database/Search/AddEditDocument";
import {addEditHandleDelete, deleteFileFirebaseStorage} from "../../utils/functions";
import {queriesForDatabaseSearch} from "../../utils/queries/QueriesForDatabaseSearch";



export const AddEditDocModal = (show, handleClose, document, setDocument, searchQuery, searchType, documents, setDocs) => {

    const handleDatabaseSearchSubmit = (e) => {
        e.preventDefault();
        queriesForDatabaseSearch(searchQuery, searchType, setDocs)
    }
    return (
        <Modal show={show}
               size={"xl"}
               onHide={handleClose}>
            <Modal.Header closeButton>
                <Col>

                {document.id === "" || document.id === undefined ?
                    <Modal.Title>Add Document</Modal.Title> :
                    <Modal.Title>Edit Document</Modal.Title>}
                </Col>

            </Modal.Header>
            <Modal.Body>
                <AddEditDocument document={document} handleClose={handleClose} searchQuery={searchQuery} searchType={searchType} setDocs={setDocs}/>
            </Modal.Body>
            <Modal.Footer>
            </Modal.Footer>
        </Modal>
    )
}