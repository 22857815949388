import {Alert, Button, ButtonGroup, Col, Form, ProgressBar, Row} from "react-bootstrap";
import React, {useState} from "react";
import {getDownloadURL, ref, uploadBytesResumable} from "firebase/storage";
import {storage} from "../../services/firebase_config/firebase";

const AbstractFileInput = (props) => {
    const [loading, setLoading] = useState({message: ""})
    const [progress, setProgress] = useState(0)

    const removeFields = (index) => {
        let data = [...props.abstractFiles];
        console.log(data[index]);
        data.splice(index, 1)
        // setInputFields(data)
        props.setAbstractFiles(data);
    }

    const handleFormChange = (e) => {
        const fileRef = ref(storage, `/reportFiles/${props.client}/${props.project}/${e.target.files[0].name}`);
        let index = props.abstractFiles.length;
        const uploadTask = uploadBytesResumable(fileRef, e.target.files[0]);
        let data = [...props.abstractFiles];
        uploadTask.on('state_changed',
            (snapshot) => {
                const progress = (snapshot.bytesTransferred / snapshot.totalBytes) * 100;
                setProgress(Math.round(progress))
                setLoading({message: "Loading..."})
            },
            (err) => {
                console.log(err)
            },
            () => {
                getDownloadURL(uploadTask.snapshot.ref)
                    .then((downloadURL) => {
                        data[index] = {name: e.target.files[0].name, downloadURL: downloadURL};
                        props.setAbstractFiles(data);
                        setLoading({message: ""})
                    })
                    .catch((err) => {
                        console.log(err)
                        setLoading({message: ""})

                    })
            });

    }

    const handleTypeChange = (type, index) => {
        let data = [...props.abstractFiles];
        data[index].type = type
        props.setAbstractFiles(data)

    }
    const handleOtherEntryChange = (e, index) => {
        let data = [...props.abstractFiles];
        data[index].type = e.target.value
        props.setAbstractFiles(data)
    }


    return (
        <div>
            <Col style={{marginTop: "5px"}}>
                <Button>
                    <label htmlFor="file">
                        Choose Files
                    </label>
                </Button>
                <Col style={{marginTop: "5px"}}>
                    <input
                        type="file"
                        id="file"
                        name="file"
                        style={{display: "none"}}
                        accept=".xlsx, .pdf"
                        onChange={(e) => handleFormChange(e)}
                    />
                </Col>
            </Col>
            {props.abstractFiles.map((file, index) => {

                return (
                    <Row key={index}>
                        <Col xs={1}>
                            <Button onClick={() => removeFields(index)}>
                                Remove
                            </Button>
                        </Col>
                        <Col>

                            <ButtonGroup size={"sm"} aria-label="outlined primary button group">
                                <Button
                                    variant={file.type === "Index" ? "primary" : "secondary"}
                                    onClick={() => {
                                        handleTypeChange("Index", index)
                                    }}>Index</Button>
                                <Button
                                    variant={file.type === "Plat" ? "primary" : "secondary"}
                                    onClick={() => {
                                        handleTypeChange("Plat", index)
                                    }}>Plat</Button>
                                <Button
                                    variant={file.type === "Memo" ? "primary" : "secondary"}
                                    onClick={() => {
                                        handleTypeChange("Memo", index)
                                    }}>Memo</Button>
                                <Button
                                    variant={file.type === "Report" ? "primary" : "secondary"}
                                    onClick={() => {
                                        handleTypeChange("Report", index)
                                    }}>Report</Button>
                                <Button
                                    variant={file.type === "" || file.type !== "Index" && file.type !== "Plat" && file.type !== "Report" && file.type !== "Memo" ? "primary" : "secondary"}
                                    onClick={() => {
                                        handleTypeChange("", index)
                                    }}>Other</Button>
                            </ButtonGroup>
                        </Col>
                        <Col>
                            <Form.Control
                                type={"text"}
                                placeholder={"Enter Type"}
                                name={"Other Type"}
                                style={{
                                    display:
                                        props.abstractFiles[index].type === "Index" ||
                                        props.abstractFiles[index].type === "Report" ||
                                        props.abstractFiles[index].type === "Memo" ||
                                        props.abstractFiles[index].type === "Plat" ? "none" : ""
                                }}
                                value={props.abstractFiles[index].type ?? ""}

                                onChange={(e) => {
                                    handleOtherEntryChange(e, index)
                                }}
                                isInvalid={false}
                            />
                        </Col>
                        <Col>
                            <p>{file.name}</p>
                        </Col>


                    </Row>
                )
            })
            }
            {/*<Row style={{marginTop: "5px"}}>*/}
            {/*    <Col>*/}
            {/*        <Button onClick={() => addFields}>*/}
            {/*            Add another File*/}
            {/*        </Button>*/}
            {/*    </Col>*/}
            {/*</Row>*/}
            <Col style={{width: "40%"}}>
                {loading.message && <Alert variant="info">{loading.message}</Alert>}
                {loading.message && <ProgressBar now={progress}/>}
            </Col>
        </div>
    )

}

export default AbstractFileInput;