import {collection, getDocs, query, where} from "firebase/firestore";
import {db} from "../../services/firebase_config/firebase";
import {exportUnlinkedList} from "../../features/Excel/exports/exportUnlinkedList";
import {exportEmptyDocuments} from "../../features/Excel/exports/exportIncompleteDocsList";

export const queriesForDatabaseSearch = (
    searchQuery, searchType, setSearchQueryResults
) => {
    let queryResults = [];

    const getSearchResults = async (props) => {
        if (searchType === "documentNumber") searchQuery = Number(searchQuery)
        const collectionRef = collection(db, "Documents");
        const docQuery = query(collectionRef,
            where(searchType, "==", searchQuery));
        const querySnapshot = await getDocs(docQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                queryResults.push({
                    ...doc.data(),
                    id: doc.id,
                })
                return null
            })
        } else {
            queryResults = [{[searchType]: "no results found"}]
        }
        setSearchQueryResults(queryResults)

    };
    getSearchResults()

};

export const queryForLinkedDocs = (
   county
) => {
    let queryResults = [];
    const getSearchResults = async () => {
        const collectionRef = collection(db, "Documents");
        const docQuery = query(collectionRef,
            where("county", "==", county));
        const querySnapshot = await getDocs(docQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                if (!doc.data().downloadURL) {
                    queryResults.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                    return null
                }
            })
        }
        console.log(queryResults)
        exportUnlinkedList(queryResults)
        // setSearchQueryResults(queryResults)

    };
    getSearchResults()
};

export const queryForUploadedDocsWithNoData = (
county
) => {
    let queryResults = [];
    const getEmptyDocSearchResults = async (county) => {

        const collectionRef = collection(db, "Documents");
        const docQuery = query(collectionRef,
            where("county", "==", county));
        const querySnapshot = await getDocs(docQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                if (doc.data().downloadURL && !doc.data().grantor && !doc.data().instrument) {
                    queryResults.push({
                        ...doc.data(),
                        id: doc.id,
                    })
                    return null
                }
            })
        }
        console.log(queryResults)
        exportEmptyDocuments(queryResults, county)

    };
    getEmptyDocSearchResults(county)
};
export const queryForSearchType = (setSearchType
) => {
    let searchTypeResults = {
        grantor: ["Select Grantor"],
        grantee: ["Select Grantee"],
        county: ["Select County"],
        description: ["Select Description"],
        documentNumber: ["Select Document Number"],
        instrument: ["Select Instrument"],
    };

    const removeDuplicateTypes = (arr) => {
        ["grantor", "county", "grantor", "grantee", "documentNumber", "instrument", "description"].forEach((type) => {
                searchTypeResults[type].push(arr[type])
                searchTypeResults[type] = [...new Set(searchTypeResults[type])]
            }
        )
    }

    const getSearchTypes = async () => {
        const collectionRef = collection(db, "Documents");
        const typeQuery = query(collectionRef)
        const querySnapshot = await getDocs(typeQuery)
        if (!querySnapshot.empty) {
            querySnapshot.docs.map(doc => {
                removeDuplicateTypes(doc.data())
                return null
            })
        }
        setSearchType(searchTypeResults)
    };
    getSearchTypes()

};


    let queryResults = [];
export const getDocumentSearchResults = async (document, county, appState, setDocument, setNoResults) => {
    console.log(document)
    console.log(county)
queryResults = [];
    const collectionRef = collection(db, "Documents");
    const docQuery = query(collectionRef,
        where("county", "==", county),
        where("document", "==", document));
    const querySnapshot = await getDocs(docQuery)
    if (!querySnapshot.empty) {
        querySnapshot.docs.map(doc => {
                queryResults.push({
                    ...doc.data(),
                    id: doc.id,
                })
        })
        setDocument(queryResults[0])
        appState.tableRows.value = queryResults
    } else {
        setNoResults("No results found")
    }

    // setSearchQueryResults(queryResults)

};
