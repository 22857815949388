import React, {useContext, useEffect, useState} from "react";
import {Row, Col, Button, Form, Container, Spinner, Table} from "react-bootstrap";

import PacerMenu from "../Menu";
import AlertBox from "../../components/Alerts/AlertBox";
import {Stack, Typography} from "@mui/material";
import {countyList, lastUpdate, numberOfDocs, selectedCounty} from "../../store/signalsStore";
import {getCountyCollectionData} from "../../utils/queries/queryForCountyCollectionData";
import {updateCountyCollectionDataWithTotalNumberOfDocuments} from "../../utils/dbFunctions";
import {useSignals} from "@preact/signals-react/runtime";
import {AppState} from "../../App";
import {effect} from "@preact/signals-react";

import {ImportDocumentsModal} from "./Import/modals/importDocumentsModal";
import {DocTable, DocumentTableHeaders, ImportResultsDocTable} from "./Search/DocumentTable";
import GrantorGranteeSearch from "./Search/GrantorGrantee";
import {getFullListForCounty} from "../../utils/queries/getFullListForCounty";
import {getDocumentSearchResults} from "../../utils/queries/QueriesForDatabaseSearch";
import {AddEditDocModal} from "../../components/modals/AddEditDocModal";
import {AbstractTableHeaders, AbTable} from "../Abstract/AbstractTable";
import AddEditDocument from "./Search/AddEditDocument";



const Search = () => {
    useSignals();
    const appState = useContext(AppState)
    const [documents, setDocs] = useState([]);
    const [document, setDocument] = useState({});
    const [county, setCounty] = useState("Albany County, Wyoming")
const [noResults, setNoResults] = useState("")
    const [show, setShow] = useState(false);
    const handleClose = () => {
        setShow(false);
    }
    const handleShow = () => setShow(!show);

    const onChange = (e) => {
        setDocument(e.target.value)
    }

    // useEffect(() => {
    //     if (county) {
    //     getFullListForCounty(selectedCounty.value, appState)
    //
    //     }
    // }, [selectedCounty.value]);



    return (
        <>
        <Container fluid>
            <Row>
                <PacerMenu/>
            </Row>
            <Row>




            </Row>

            <Row style={{marginTop: "5px"}}>
                <Col>
                    <Row style={{margin: "10px"}}>
                        <Col xs={2}>

                            <Form.Label>{"Select County:"}</Form.Label>
                            <Form.Select
                                name={"county"}
                                onChange={(e) => {
                                    numberOfDocs.value = undefined
                                    setCounty(e.target.value)
                                    selectedCounty.value = e.target.value;
                                    appState.alertMessage.value = "Selected " + e.target.value;
                                    appState.alertSeverity.value = "info";
                                }}
                            >

                                {countyList.value.map((county, index) => {
                                    return (
                                        <option key={index}>{county}</option>)
                                })

                                }
                            </Form.Select>
                        </Col>
                    </Row>
                    {county ?
                        <>
                        <Typography>For: {county}</Typography>

                        </>
                        :
<></>
                        }
                    <GrantorGranteeSearch onChange={onChange} appState={appState} county={county} document={document} setDocument={setDocument} setNoResults={setNoResults} />




                </Col>
            </Row>
            {appState.tableRows.value.length > 1 ?
            <Table striped bordered hover>
                {DocumentTableHeaders}
                <tbody>
                <DocTable
                    handleShow={handleShow}
                    setDocument={setDocument}
                    appState={appState}
                />
                </tbody>
            </Table> :
                <></>}
            {document.county  ?
            <AddEditDocument document={document} handleClose={handleClose}  setDocs={setDocs} setDocument={setDocument}/>
            : <>{noResults}</>
            }
                {/*<DocTable appState={appState} setDocument={setDocument} handleShow={handleShow}/>*/}
        </Container>
            {/*{AddEditDocModal(show, handleClose, document)}*/}

        </>
            )
};
export default Search;