import {Alert, Button, Col, Form, ProgressBar, Row} from "react-bootstrap";
import React from "react";
import {getDocumentSearchResults} from "../../../utils/queries/QueriesForDatabaseSearch";
import {Stack} from "@mui/material";

const GrantorGranteeSearch = (props) => {
    const handleSubmit = (props) => {

        getDocumentSearchResults(props.document, props.county, props.appState, props.setDocument, props.setNoResults)
    }


    return (
        <Form >

                    <Form.Label>{"Document Number"}</Form.Label>
             <Stack direction={"row"}>
                    <Form.Control
                        type={"text"}
                        style={{ width: '20%' }}
                        placeholder={"Search Document Number"}
                        name={"documentNumber"}
                        isInvalid={false}
                        onChange={(e) => {props.onChange(e)}}
                    />

                    <Button style={{marginLeft:"5px"}} onClick={() => {handleSubmit(props)}}
                    >Search</Button>
             </Stack>



        </Form>
    )
}

export default GrantorGranteeSearch;