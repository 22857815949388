import {Modal, ModalBody, ModalFooter, Form, Col, Button, Row} from "react-bootstrap";
import {importDocumentChangeHandler} from "../../../../features/Excel/imports/importChangeHandlers";
import React, {useContext, useState} from "react";
import {AppState} from "../../../../App";

export const ImportDocumentsModal = (
    show,
    handleClose,
    document,
    setDocument,
    setShowImportModal,
    showDuplicates,
    handleShowDuplicates,
    replaceAll,
    handleReplaceAll,
) => {
    const appState = useContext(AppState)
    const [verifyAll, setVerifyAll] = useState(false);
    return (
        <Modal show={show} size="lg" onHide={handleClose}>
            <Modal.Header closeButton>
                <Modal.Title>Import Documents</Modal.Title>
            </Modal.Header>
            <ModalBody>
                <Row>
                <Col style={{marginTop: "5px", marginLeft: "20px"}}>
                <input type="file" id="file" name="file" style={{display:"none"}} accept=".xlsx"
                       onChange={(e) => {
                           setShowImportModal(false)
                           importDocumentChangeHandler(e, showDuplicates, replaceAll, verifyAll, appState)
                           appState.alertMessage.value = "Starting import";
                           appState.alertSeverity.value = "info";
                           appState.alertProgress.value = 10;
                       }}
                />
                    <Button>
                        <label htmlFor="file">Choose File</label>
                    </Button>
                </Col>
                </Row>
            </ModalBody>
            <ModalFooter>
                <Form.Check
                    checked={verifyAll}
                    type="switch"
                    label="Verify all?"
                    id="duplicate-switch"
                    onChange={() => {setVerifyAll(!verifyAll)}}
                />
            </ModalFooter>
        </Modal>
    )
}