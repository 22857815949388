import React from "react";
import {Row, Col,} from "react-bootstrap"
import {PencilSquare, Book, BookFill} from "react-bootstrap-icons";
import {ExcelDateToJSDate} from "../../../utils/functions";
import {useSignals} from "@preact/signals-react/runtime";

export const DocumentTableHeaders =
    <thead>
    <tr>
        <th>Edit</th>
        <th>Document</th>
        <th>Instrument</th>
        {/*<th>Grantor</th>*/}
        {/*<th>Grantee</th>*/}
        {/*<th>Filed_Date</th>*/}
        {/*<th>Received_Date</th>*/}
        {/*<th>Requisition Number</th>*/}
        {/*<th>Book</th>*/}
        {/*<th>Page</th>*/}
        {/*<th>Effective Date</th>*/}
        {/*<th>Description</th>*/}
        {/*<th>WOL</th>*/}
        {/*<th>Comments</th>*/}
        {/*<th>County/State</th>*/}
    </tr>
    </thead>
;

export const DocTable = (props) => {
    useSignals();
    if (props.appState.tableRows.value !== undefined) {
        const documentsSort = props.appState.tableRows.value.sort((a, b) => a.documentNumber - b.documentNumber)
        return (

        documentsSort.map((document, index) => {
                return (
                    <tr key={index}>
                        <td>
                            <Row>
                                {/*<Col>*/}
                                {/*    {document.downloadURL ?*/}
                                {/*        <Book onClick={() => {*/}
                                {/*            window.open(document.downloadURL)*/}
                                {/*        }}*/}
                                {/*        /> : <BookFill onClick={() => {*/}
                                {/*            alert("Pdf not uploaded")*/}
                                {/*        }}*/}
                                {/*        />*/}
                                {/*    }*/}
                                {/*</Col>*/}
                                <Col>
                                    <PencilSquare onClick={() => {
                                        console.log(document)
                                        props.setDocument(document)
                                        props.handleShow()
                                    }}
                                    />
                                </Col>
                            </Row>
                        </td>

                        <td>{document.document}</td>
                        <td>{document.instrument}</td>

                    </tr>

                )
            }))
    }

};


export const ImportResultsDocTable = (props) => {
    useSignals();
    const documentsSort = props.appState.tableRows.value.sort((a, b) => a.documentNumber - b.documentNumber)
    const updateSort = props.appState.importStats.value.updated.sort((a, b) => a.documentNumber - b.documentNumber)
    return (
        <>
            {documentsSort.length > 0 ?

                <thead>
                <tr>
                    <th>Documents Added:</th>
                </tr>
                </thead> : <></>
            }
            {documentsSort.map((document, index) => {
                return (
                    <tr key={index}>
                        <td>{document.document}</td>
                    </tr>
                )
            })}
            {updateSort.length > 0 ?

                <thead>
                <tr>
                    <th>Documents Updated:</th>
                </tr>
                </thead> : <></>
            }
            {updateSort.map((document, index) => {
                return (
                    <tr key={index}>
                        <td>{document.old.document}</td>
                    </tr>
                )
            })}
        </>
    )
};
