import React, {useState, useEffect} from "react";
import {addDoc, collection, doc, updateDoc} from 'firebase/firestore';
import {Col, Row, Form, Button, Container, Card} from 'react-bootstrap';
import {db} from "../../../services/firebase_config/firebase";

import {emptyDocument} from "../../../utils/containers";
import {addEditHandleDelete, deleteFileFirebaseStorage, ExcelDateToJSDate, onChange} from "../../../utils/functions";
import {queriesForDatabaseSearch} from "../../../utils/queries/QueriesForDatabaseSearch";
import {Stack} from "@mui/material";
import {v4 as uuidv4} from 'uuid';
const AddEditDocuments = (props) => {
    const [documentDetails, setDocumentDetails] = useState(emptyDocument)
    const [iframeKey, setIframeKey] = useState(uuidv4());
    const [fDate, setFDate] = useState(ExcelDateToJSDate(props.document.fileDate))
    const [exDate, setExDate] = useState(ExcelDateToJSDate(props.document.executedDate))
    const [effDate, setEffDate] = useState(ExcelDateToJSDate(props.document.effectiveDate))

    const onSubmit = () => {
        //if (documentDetails.documentNumber !== undefined ) {documentDetails.document = documentDetails.documentNumber.toString()}
        //if (documentDetails.book !== "") {documentDetails.document = documentDetails.book.toString().padStart(4, "0") + "-" + documentDetails.page.toString().padStart(4, "0") }
        if (documentDetails.id) {
            const docRef = doc(db, "Documents", `${documentDetails.id}`);
            updateDoc(docRef, documentDetails);
        } else {
            const collectionRef = collection(db, "Documents");
            addDoc(collectionRef, documentDetails).then(() => {
                return null
            });
        }
        props.handleClose()
    }
    const handleDatabaseSearchSubmit = (e) => {
        e.preventDefault();
        queriesForDatabaseSearch(props.searchQuery, props.searchType, props.setDocs)
    }

    useEffect(() => {
        setIframeKey(uuidv4());
    }, [props.document.downloadURL]);

    useEffect(() => {
        if (props.document.id !== undefined || props.document.documentNumber !== undefined) setDocumentDetails(props.document)

    }, [props.show, props.document]);

    return (
        <>
            <Row style={{margin: "10px"}}>

            <Col>
                <Button
                    onClick={(e) => {
                        addEditHandleDelete("Documents", document.id)
                        handleDatabaseSearchSubmit(e)
                    }}
                >
                    Delete Document
                </Button>
            </Col>
            <Col style={{paddingLeft: "80px"}}>


                <Button
                    onClick={(e) => {
                        deleteFileFirebaseStorage(`/Counties/${document.county}/${document.document}.pdf`, "Documents", document.id)
                        handleDatabaseSearchSubmit(e)
                        props.setDocument({...document, downloadURL: null});
                    }}
                >
                    Delete PDF
                </Button>

            </Col>
        </Row>
            <Stack direction={"row"} sx={{ width: "100%",height: "100vh" }}>
                <Container sx={{ width: "10%" }}>
                <Form noValidate>
                    <Row>
                        <Col>
                            <Row>
                                <Col>
                                    <Form.Label>{"Instrument"}</Form.Label>
                                </Col>

                            </Row>
                            <Form.Control
                                as="textarea" rows={1}
                                type={"text"}
                                placeholder={"Document Type"}
                                name={"instrument"}
                                value={props.document.instrument}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />
                            <Row>
<Col>

                            <Form.Label>{"Document"}</Form.Label>
</Col>

                            </Row>
                            <Form.Control
                                type={"text"}
                                placeholder={"Document"}
                                name={"Document"}
                                value={props.document.document}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />

                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{"Grantor"}</Form.Label>
                            <Form.Control
                                as="textarea" rows={1}
                                type={"text"}
                                placeholder={"name of grantor"}
                                name={"grantor"}
                                value={props.document.grantor}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{"Grantee"}</Form.Label>
                            <Form.Control
                                as="textarea" rows={1}
                                type={"text"}
                                placeholder={"name of grantee"}
                                name={"grantee"}
                                value={props.document.grantee}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{"Effective Date"}</Form.Label>
                            <Form.Control
                                type={"text"}
                                placeholder={"enter date"}
                                name={"effectiveDate"}
                                value={effDate}
                                onChange={(e) => {
                                    setEffDate(e.target.value)
                                    onChange(e, props.document, setDocumentDetails)}}
                                isInvalid={false}
                            />
                        </Col>
                        <Col>
                            <Form.Label>{"Exec. Date"}</Form.Label>
                            <Form.Control
                                type={"text"}
                                placeholder={"enter date"}
                                name={"executedDate"}
                                value={exDate}
                                onChange={(e) => {
                                    setExDate(e.target.value)
                                    onChange(e, props.document, setDocumentDetails)}}
                                isInvalid={false}
                            />
                        </Col>
                        <Col>
                            <Form.Label>{"File Date"}</Form.Label>
                            <Form.Control
                                type={"text"}
                                placeholder={"enter date"}
                                name={"fileDate"}
                                value={fDate}
                                onChange={(e) => {
                                    setFDate(e.target.value)
                                    onChange(e, props.document, setDocumentDetails)}}

                                isInvalid={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Form.Label>{"Requisition Number"}</Form.Label>
                        <Form.Control
                            type={"number"}
                            placeholder={"enter requisistion number"}
                            name={"reqNumber"}
                            value={props.document.reqNumber}
                            onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                            isInvalid={false}
                        />
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{"Book"}</Form.Label>
                            <Form.Control
                                placeholder={"enter book"}
                                name={"book"}
                                type={"string"}
                                value={props.document.book}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />
                        </Col>
                        <Col>
                            <Form.Label>{"Page"}</Form.Label>
                            <Form.Control
                                type={"string"}
                                placeholder={"enter page number"}
                                name={"page"}
                                value={props.document.page}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />
                        </Col>
                    </Row>
                    <Row>
                        <Col>
                            <Form.Label>{"County"}</Form.Label>
                            <Form.Control
                                type={"text"}
                                placeholder={"enter county"}
                                name={"county"}
                                value={props.document.county}
                                onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                                isInvalid={false}
                            />
                        </Col>
                    </Row>
                    <Form.Label>{"Description"}</Form.Label>
                    <Form.Control
                        as="textarea" rows={1}
                        type={"text"}
                        placeholder={"enter description"}
                        name={"description"}
                        value={props.document.description}
                        onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                        isInvalid={false}
                    />
                    <Form.Label>{"Comments"}</Form.Label>
                    <Form.Control
                        as="textarea" rows={1}
                        type={"text"}
                        placeholder={"enter comments"}
                        name={"comments"}
                        value={props.document.comments}
                        onChange={(e) => onChange(e, props.document, setDocumentDetails)}
                        isInvalid={false}
                    />
                    <Button onClick={onSubmit}>Submit</Button>
                </Form>
            </Container>
                {props.document.downloadURL ?
                    <div className="PDFSection" style={{width: "90%",height: "100%"}}>

                            <iframe key={iframeKey} src={props.document.downloadURL} width="100%" height="100%"></iframe>

                     </div>
                    :
                    <p>No PDF found</p>
                }
                </Stack>
        </>);
};

export default AddEditDocuments;