/*
**Alert**
* progress, alert message, loading
*
* *Refs**
* storage, docRef, collectionRef,
*
* *documents**
* url, documentDetails...
*
* *queries**
* docQueryResults,
*
* *reports**
* headerDetails, report, files, title, documentList
*
* *clients**
* clients, reportList
*
* *admin**
* users, roles
 */
import {collection, query, where} from "firebase/firestore";
import {db} from "../services/firebase_config/firebase"
import {computed, signal} from "@preact/signals-react";

//AlertBox
export const alert = signal({
    message: "test",
    variant: "",
    progress: 0,
    loading: false
});

//Documents
export const documents = signal(undefined);
export const docList = signal(undefined);
export const docUrl = signal(undefined);
export const documentSignal = signal({document: "", county: ""})

//Refs
export const docRef = signal(undefined);
export const headerDetailsSignal = signal({
    project: "",
    county: "",
    townshipAndRange: "",
    section: "",
    date:  "",
    acreage:  "",
    client:  "",
    lastPage:  ""
});
export const collectionRefSignal = computed( () => {return collection(db, "Documents")})
export const clientCollectionRefSignal = computed( () => {return collection(db, "clientData")})

//queries

export const queryResultSignal = signal(undefined);

export const countyList = signal(
    [
        "Albany County, Wyoming",
        "Campbell County, Wyoming",
        "Cheyenne County, Nebraska",
        "Converse County, Wyoming",
        "Dunn County, North Dakota",
        "Eddy County, New Mexico",
        "Johnson County, Wyoming",
        "Laramie County, Wyoming",
        "Lincoln County, Wyoming",
        "McKenzie County, North Dakota",
        "Mountrail County, North Dakota",
        "Niobrara County, Wyoming",
        "Sweetwater County, Wyoming",
        "Uinta County, Wyoming",
        "Weld County, Colorado",
    ]
)
export const selectedCounty = signal()

export const numberOfDocs = signal(0)
export const lastUpdate = signal(new Date())
// Grantor Grantee

export const countySearchListSignal = signal("");
export const documentSearchListSignal = signal("");
export const instrumantSearchListSignal = signal("");
export const grantorSearchListSignal = signal("")
export const granteeSearchListSignal = signal("")
export const descriptionSearchListSignal = signal("")
export const countyQuerySignal = signal("")
export const documentQuerySignal = signal("")
export const instrumentQuerySignal = signal("")
export const grantorQuerySignal = signal("")
export const granteeQuerySignal = signal("")
export const descriptionQuerySignal = signal("")

export const fullSearchList = computed(() => {
    let list = [];
    //Object.values(schools.value).map((school) => {

    //     list.push({name: school.schoolName, id: school.schoolId, code: school.schoolCode})
    // })
    // Object.entries(districts.value).map((district) => {
    //     list.push({name: district[1].schoolName, id: district[0], code: "D"})
    // })
    return list
})