import {sheetStyles} from "../sheetStyles";
import {ExcelDateToJSDate} from "../../../utils/functions";
import {setCells, setMergesByArea, setMergesByRow} from "../ExcelFormattingUtilities";



export const converseCountyLinked = (sheet: any, data: any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`
    const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
    sheet.columns = [
        {width: 15}, //instrument
        {width: 15}, // document number
        {width: 20}, // instrument type
        {width: 30}, //grantor
        {width: 30}, // grantee
        {width: 12}, // recorded date
        {width: 17}, // effective data
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7}, // wol
        {width: 40}, // comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);
    let filesEndIndex = 0;
    let rowIndex = 0;
    let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dl"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `${storageURL}/reportFiles/${data.header[0].client}/${data.header[0].project}/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} l${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    setCells(sheet, filesEndIndex + 1, [
        {a: "INSTRUMENT", style: sheetStyles.headerStyleGrey},
        {b: "DOCUMENT NO.", style: sheetStyles.headerStyleGrey},
        {c: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {d: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {e: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {f: "REC. DATE", style: sheetStyles.headerStyleGrey},
        {g: "EFFECTIVE DATE", style: sheetStyles.headerStyleGrey},
        {h: "BOOK", style: sheetStyles.headerStyleGrey},
        {i: "PAGE", style: sheetStyles.headerStyleGrey},
        {j: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {k: "WOL", style: sheetStyles.headerStyleGrey},
        {l: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ])


    let listEndIndex = 0
    let docNumber,
        style;
    data.list.map((row: any, index: number) => {
        let verifiedStyle;
        if (row.verified) {verifiedStyle = sheetStyles.textStyle} else {verifiedStyle = sheetStyles.unverified}


        // if (row.downloadURL !== "") {
        //     docNumber = {
        //         text: row.document,
        //         hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        //     }
        //     style = sheetStyles.hyperLinkStyle
        // } else {
        //     docNumber = row.document;
        //     style = sheetStyles.textStyle
        // }
        ///TODO: The above code disables the hyperlink if URL is not present. Disabled at client's request. Code below adds hyperlink regardless.
        docNumber = {
            text: row.document,
            hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        };
        style = sheetStyles.hyperLinkStyle


        let rowIndex = index + filesEndIndex + 2;
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: docNumber, style: style},
            {b: row.reqNumber, style: sheetStyles.textStyle},
            {c: row.instrument, style: verifiedStyle},
            {d: row.grantor, style: sheetStyles.textStyle},
            {e: row.grantee, style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.fileDate), style: sheetStyles.textStyle},
            {g: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {h: row.book, style: sheetStyles.textStyle},
            {i: row.page, style: sheetStyles.textStyle},
            {j: row.description, style: sheetStyles.textStyle},
            {k: row.wol, style: sheetStyles.textStyle},
            {l: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

    setCells(sheet, listEndIndex + 1, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, listEndIndex  + 2, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7], ["al"]);
    setMergesByRow(sheet, [listEndIndex  + 1], ["al"]);
    setMergesByArea(sheet, [`a${listEndIndex  + 2} l${listEndIndex + 7}`]);

}
export const weldCountyLinked = (sheet: any, data: any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`
    const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
    sheet.columns = [
        {width: 15}, //document
        {width: 20}, // instrument
        {width: 30}, //grantor
        {width: 30}, // grantee
        {width: 12}, // dated
        {width: 12}, // received
        {width: 12}, // effective
        {width: 10}, // req number
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7}, // wol
        {width: 40}, // comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);
    let filesEndIndex = 0;
    let rowIndex = 0;
        let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dm"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `${storageURL}/reportFiles/${data.header[0].client}/${data.header[0].project}/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} m${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    setCells(sheet, filesEndIndex + 1, [
        {a: "DOCUMENT", style: sheetStyles.headerStyleGrey},
        {b: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {c: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {d: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {e: "DATED", style: sheetStyles.headerStyleGrey},
        {f: "RECEIVED", style: sheetStyles.headerStyleGrey},
        {g: "EFFECTIVE", style: sheetStyles.headerStyleGrey},
        {h: "DOC. #", style: sheetStyles.headerStyleGrey},
        {i: "BOOK", style: sheetStyles.headerStyleGrey},
        {j: "PAGE", style: sheetStyles.headerStyleGrey},
        {k: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {l: "WOL", style: sheetStyles.headerStyleGrey},
        {m: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ])


    let listEndIndex = 0
    let docNumber,
        style;
    data.list.map((row: any, index: number) => {
        let verifiedStyle;
        if (row.verified) {verifiedStyle = sheetStyles.textStyle} else {verifiedStyle = sheetStyles.unverified}


        // if (row.downloadURL !== "") {
        //     docNumber = {
        //         text: row.document,
        //         hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        //     }
        //     style = sheetStyles.hyperLinkStyle
        // } else {
        //     docNumber = row.document;
        //     style = sheetStyles.textStyle
        // }
        ///TODO: The above code disables the hyperlink if URL is not present. Disabled at client's request. Code below adds hyperlink regardless.
        docNumber = {
                    text: row.document,
                    hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
                };
                style = sheetStyles.hyperLinkStyle


        let rowIndex = index + filesEndIndex + 2;
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: docNumber, style: style},
            {b: row.instrument, style: verifiedStyle},
            {c: row.grantor, style: sheetStyles.textStyle},
            {d: row.grantee, style: sheetStyles.textStyle},
            {e: ExcelDateToJSDate(row.fileDate), style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.receivedDate), style: sheetStyles.textStyle},
            {g: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {h: row.reqNumber, style: sheetStyles.textStyle},
            {i: row.book, style: sheetStyles.textStyle},
            {j: row.page, style: sheetStyles.textStyle},
            {k: row.description, style: sheetStyles.textStyle},
            {l: row.wol, style: sheetStyles.textStyle},
            {m: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

    const lastPage = data.header[0].lastPage ?? ""
    setCells(sheet, listEndIndex  + 1, [{a: "LAST PAGE: " + lastPage, style: sheetStyles.headerStyle}]);
    setCells(sheet, listEndIndex + 2, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, listEndIndex  + 3, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7], ["am"]);
    setMergesByRow(sheet, [listEndIndex  + 1], ["am"]);
    setMergesByRow(sheet, [listEndIndex + 2], ["am"]);
    setMergesByArea(sheet, [`a${listEndIndex  + 3} m${listEndIndex + 7}`]);

}

export const dunnCountyLinked = (sheet: any, data: any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`

    const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
    sheet.columns = [
        {width: 15},// document
        {width: 20},// instrument
        {width: 30},// grantor
        {width: 30},// grantee
        {width: 15},// rec date
        {width: 15}, // eff date
        {width: 10}, // reqnumber
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7},// wol
        {width: 40},// comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowTopBorder}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);
    let filesEndIndex = 0;
    let rowIndex = 0;

    let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dm"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `${storageURL}/reportFiles/${data.header[0].client}/${data.header[0].project}/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} m${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    setCells(sheet, filesEndIndex + 1, [
        {a: "DOCUMENT", style: sheetStyles.headerStyleGrey},
        {b: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {c: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {d: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {e: "REC. DATE", style: sheetStyles.headerStyleGrey},
        {f: "EFFECTIVE DATE", style: sheetStyles.headerStyleGrey},
        {g: "DOC. #", style: sheetStyles.headerStyleGrey},
        {h: "BOOK", style: sheetStyles.headerStyleGrey},
        {i: "PAGE", style: sheetStyles.headerStyleGrey},
        {j: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {k: "WOL", style: sheetStyles.headerStyleGrey},
        {l: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ])


    let listEndIndex = 0
    let docNumber,
        style;
    data.list.map((row: any, index: number) => {
        let verifiedStyle;
        if (row.verified) {verifiedStyle = sheetStyles.textStyle} else {verifiedStyle = sheetStyles.unverified}
        // if (row.downloadURL !== "") {
        //     docNumber = {
        //         text: row.document,
        //         hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        //     }
        //     style = sheetStyles.hyperLinkStyle
        // } else {
        //     docNumber = row.document;
        //     style = sheetStyles.textStyle
        // }
        ///TODO: The above code disables the hyperlink if URL is not present. Disabled at client's request. Code below adds hyperlink regardless.
        docNumber = {
            text: row.document,
            hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        };
        style = sheetStyles.hyperLinkStyle



        let rowIndex = index + filesEndIndex + 2;
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: docNumber, style: style},
            {b: row.instrument, style: verifiedStyle},
            {c: row.grantor, style: sheetStyles.textStyle},
            {d: row.grantee, style: sheetStyles.textStyle},
            {e: ExcelDateToJSDate(row.fileDate), style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {g: row.reqNumber, style: sheetStyles.textStyle},
            {h: row.book, style: sheetStyles.textStyle},
            {i: row.page, style: sheetStyles.textStyle},
            {j: row.description, style: sheetStyles.textStyle},
            {k: row.wol, style: sheetStyles.textStyle},
            {l: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

const lastPage = data.header[0].lastPage ?? ""
    setCells(sheet, listEndIndex  + 1, [{a: "LAST PAGE:" + lastPage, style: sheetStyles.headerStyle}]);
    setCells(sheet, listEndIndex + 2, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, listEndIndex  + 3, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7], ["am"]);
    setMergesByRow(sheet, [listEndIndex  + 1], ["am"]);
    setMergesByRow(sheet, [listEndIndex + 2], ["am"]);
    setMergesByArea(sheet, [`a${listEndIndex  + 3} m${listEndIndex + 7}`]);

}

export const weldCountyZipped = (sheet : any, data : any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`

    sheet.columns = [
        {width: 15}, //doc
        {width: 20}, // instrument
        {width: 30}, //grantor
        {width: 30}, // grantee
        {width: 12}, // dated
        {width: 12}, // received
        {width: 12}, // effective
        {width: 10}, // reqnumber
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7}, // wol
        {width: 40}, // comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);

let filesEndIndex= 7;
    let rowIndex = 0;

    let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dm"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `./files/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} m${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    let listEndIndex = 0

    setCells(sheet, filesEndIndex + 1 , [
        {a: "DOCUMENT", style: sheetStyles.headerStyleGrey},
        {b: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {c: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {d: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {e: "DATED", style: sheetStyles.headerStyleGrey},
        {f: "RECEIVED", style: sheetStyles.headerStyleGrey},
        {g: "EFFECTIVE", style: sheetStyles.headerStyleGrey},
        {h: "DOC. #", style: sheetStyles.headerStyleGrey},
        {i: "BOOK", style: sheetStyles.headerStyleGrey},
        {j: "PAGE", style: sheetStyles.headerStyleGrey},
        {k: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {l: "WOL", style: sheetStyles.headerStyleGrey},
        {m: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ]);

    data.list.map((row : any, index : any) => {
        let  docNumber = {
            text: row.document,
            hyperlink: `./documents/${row.document}.pdf`,
        }
        let rowIndex = index + filesEndIndex + 2
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: docNumber, style: sheetStyles.hyperLinkStyle},
            {b: row.instrument, style: sheetStyles.textStyle},
            {c: row.grantor, style: sheetStyles.textStyle},
            {d: row.grantee, style: sheetStyles.textStyle},
            {e: ExcelDateToJSDate(row.fileDate), style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.receivedDate), style: sheetStyles.textStyle},
            {g: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {h: row.reqNumber, style: sheetStyles.textStyle},
            {i: row.book, style: sheetStyles.textStyle},
            {j: row.page, style: sheetStyles.textStyle},
            {k: row.description, style: sheetStyles.textStyle},
            {l: row.wol, style: sheetStyles.textStyle},
            {m: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

    const lastPage = data.header[0].lastPage ?? ""
    setCells(sheet, listEndIndex + 1, [{a: "LAST PAGE:" + lastPage, style: sheetStyles.headerStyle}]);
    setCells(sheet, listEndIndex + 2, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, listEndIndex + 3, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7, listEndIndex + 1, listEndIndex + 2], ["am"]);
    setMergesByArea(sheet, [`a${listEndIndex + 3} m${listEndIndex + 6}`]);

}

export const dunnCountyZipped = (sheet : any, data : any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`

    sheet.columns = [
        {width: 10},// page no
        {width: 15},// doc no
        {width: 20},// instrument
        {width: 30},// grantor
        {width: 30},// grantee
        {width: 15},// rec date
        {width: 15}, // eff date
        {width: 10}, // reqNumber
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7},// wol
        {width: 40},// comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);

    let filesEndIndex= 7;
    let rowIndex = 0;


    let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dm"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `./files/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} m${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    let listEndIndex = 0

    setCells(sheet, filesEndIndex + 1, [
        {a: "PAGE NO.", style: sheetStyles.headerStyleGrey},
        {b: "DOCUMENT", style: sheetStyles.headerStyleGrey},
        {c: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {d: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {e: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {f: "REC. DATE", style: sheetStyles.headerStyleGrey},
        {g: "EFFECTIVE DATE", style: sheetStyles.headerStyleGrey},
        {h: "DOC. #", style: sheetStyles.headerStyleGrey},
        {i: "BOOK", style: sheetStyles.headerStyleGrey},
        {j: "PAGE", style: sheetStyles.headerStyleGrey},
        {k: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {l: "WOL", style: sheetStyles.headerStyleGrey},
        {m: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ])

    data.list.map((row : any, index : any) => {
        let  docNumber = {
            text: row.document,
            hyperlink: `./documents/${row.document}.pdf`,
        }
        let rowIndex = index + filesEndIndex + 2
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: "", style: sheetStyles.textStyle},
            {b: docNumber, style: sheetStyles.hyperLinkStyle},
            {c: row.instrument, style: sheetStyles.textStyle},
            {d: row.grantor, style: sheetStyles.textStyle},
            {e: row.grantee, style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.fileDate), style: sheetStyles.textStyle},
            {g: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {h: row.reqNumber, style: sheetStyles.textStyle},
            {i: row.book, style: sheetStyles.textStyle},
            {j: row.page, style: sheetStyles.textStyle},
            {k: row.description, style: sheetStyles.textStyle},
            {l: row.wol, style: sheetStyles.textStyle},
            {m: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

    const lastPage = data.header[0].lastPage ?? ""
    setCells(sheet, listEndIndex + 1, [{a: "LAST PAGE:" + lastPage, style: sheetStyles.headerStyle}]);
    setCells(sheet, listEndIndex + 2, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, listEndIndex + 3, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7, listEndIndex + 1, listEndIndex + 2], ["am"]);
    setMergesByArea(sheet, [`a${listEndIndex + 3} m${listEndIndex + 6}`]);

}

export const mountrailAndMcKenzieCountyLinked = (sheet: any, data: any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`
    const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
    sheet.columns = [
        {width: 15}, //document
        {width: 20}, // instrument
        {width: 30}, //grantor
        {width: 30}, // grantee
        {width: 12}, // received
        {width: 12}, // effective
        {width: 10}, // req number
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7}, // wol
        {width: 40}, // comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);
    let filesEndIndex = 0;
    let rowIndex = 0;
    let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dl"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `${storageURL}/reportFiles/${data.header[0].client}/${data.header[0].project}/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} l${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    setCells(sheet, filesEndIndex + 1, [
        {a: "DOCUMENT", style: sheetStyles.headerStyleGrey},
        {b: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {c: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {d: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {e: "REC. DATE", style: sheetStyles.headerStyleGrey},
        {f: "EFFECTIVE DATE", style: sheetStyles.headerStyleGrey},
        {g: "DOC. #", style: sheetStyles.headerStyleGrey},
        {h: "BOOK", style: sheetStyles.headerStyleGrey},
        {i: "PAGE", style: sheetStyles.headerStyleGrey},
        {j: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {k: "WOL", style: sheetStyles.headerStyleGrey},
        {l: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ])


    let listEndIndex = 0
    let docNumber,
        style;
    data.list.map((row: any, index: number) => {
        let verifiedStyle;
        if (row.verified) {verifiedStyle = sheetStyles.textStyle} else {verifiedStyle = sheetStyles.unverified}


        // if (row.downloadURL !== "") {
        //     docNumber = {
        //         text: row.document,
        //         hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        //     }
        //     style = sheetStyles.hyperLinkStyle
        // } else {
        //     docNumber = row.document;
        //     style = sheetStyles.textStyle
        // }
        ///TODO: The above code disables the hyperlink if URL is not present. Disabled at client's request. Code below adds hyperlink regardless.
        docNumber = {
            text: row.document,
            hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        };
        style = sheetStyles.hyperLinkStyle


        let rowIndex = index + filesEndIndex + 2;
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: docNumber, style: style},
            {b: row.instrument, style: verifiedStyle},
            {c: row.grantor, style: sheetStyles.textStyle},
            {d: row.grantee, style: sheetStyles.textStyle},
            {e: ExcelDateToJSDate(row.receivedDate), style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {g: row.reqNumber, style: sheetStyles.textStyle},
            {h: row.book, style: sheetStyles.textStyle},
            {i: row.page, style: sheetStyles.textStyle},
            {j: row.description, style: sheetStyles.textStyle},
            {k: row.wol, style: sheetStyles.textStyle},
            {l: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

    const lastPage = data.header[0].lastPage ?? ""
    setCells(sheet, listEndIndex  + 1, [{a: "LAST PAGE: " + lastPage, style: sheetStyles.headerStyle}]);
    setCells(sheet, listEndIndex + 2, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, listEndIndex  + 3, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7], ["al"]);
    setMergesByRow(sheet, [listEndIndex  + 1], ["al"]);
    setMergesByRow(sheet, [listEndIndex + 2], ["al"]);
    setMergesByArea(sheet, [`a${listEndIndex  + 3} l${listEndIndex + 7}`]);

}


export const laramieCountyLinked = (sheet: any, data: any) => {
    const legalDisclaimer = `(1)  This Updated Abstract of Title does not purport to cover matters not revealed by the materials examined, such as undisclosed liens, fraud, capacity of parties, genuineness of instruments and signatures thereto, adequacy or payment of consideration, actual notice of subsequent purchasers of prior unrecorded conveyances, prior dedication of gas reserves in interstate commerce, improperly or inadequately described conveyances, area, boundary lines, survey lines, conflicts of boundary lines, vacancies, excesses, rights of parties in possession, or any matters which could be determined only by an investigation upon the ground or by a survey of the land.  (2)  This Updated Abstract of Title should not be construed as rendering a legal opinion as to any matter covered therein.  (3)  No liability for the accuracy of the information provided herein is expressed or implied.  This limitation of liability also includes any errors or omissions in this report caused by documents being improperly indexed, indistinctly indexed and/or mis-indexed by ${data.header[0].county.split(",")[0]} Clerk Recording in their records for ${data.header[0].county}.  (4)  This Updated Abstract of Title has been prepared by Pacer Energy, LLC, for the exclusive use of Pacer Energy, LLC, and its clients, and should not be relied upon by any unauthorized third party.`
    const storageURL = "https://storage.googleapis.com/documentdatabase-d2450.appspot.com"
    sheet.columns = [
        {width: 15}, //document
        {width: 20}, // instrument
        {width: 30}, //grantor
        {width: 30}, // grantee
        {width: 12}, // dated
        {width: 12}, // effective
        {width: 15}, // req number
        {width: 10}, // book
        {width: 10}, // page
        {width: 20}, // description
        {width: 7}, // wol
        {width: 40}, // comments
    ];

    setCells(sheet, 1, [{a: data.reportTitle, style: sheetStyles.headerStyleYellowSideAndTopBorders}]);
    setCells(sheet, 2, [{a: data.header[0].townshipAndRange, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 3, [{a: "Section " + data.header[0].section, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 4, [{a: data.header[0].acreage + " Gross Acres", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 5, [{a: data.header[0].county, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 6, [{a: data.header[0].date, style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, 7, [{a: "(In Recording Date Order)", style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);
    let filesEndIndex = 0;
    let rowIndex = 0;
    let filesStartIndex = 9;
    if (data.files[0] !== undefined) {
        setCells(sheet, 8, [{a: "SUPPLEMENTAL FILES:", style: sheetStyles.headerStyleGrey}, {b: "" ,style: sheetStyles.headerStyleGrey}, {d: "" ,style: sheetStyles.headerStyleGreyTopAndSideBorders}]);
        setMergesByRow(sheet, [8], ["ac"])
        setMergesByRow(sheet, [8], [ "dl"])
        data.files.map((row: any, index: number) => {
            rowIndex = index + filesStartIndex;
            setCells(sheet, rowIndex, [
                {a: row.type + ": ", style: sheetStyles.headerStyleGrey},
                {b: {
                        text: row.name,
                        hyperlink: `${storageURL}/reportFiles/${data.header[0].client}/${data.header[0].project}/${row.name}`,
                    }, style: sheetStyles.alignLeftLink},
                {d: "", style: sheetStyles.headerStyleGreySideBorders}
            ])
            setMergesByArea(sheet, [`b${rowIndex} c${rowIndex}` ])
            setMergesByArea(sheet, [`d${rowIndex} l${rowIndex}` ])
            filesEndIndex = rowIndex
        })
    } else {
        filesEndIndex =  7;
    }

    setCells(sheet, filesEndIndex + 1, [
        {a: "DOCUMENT", style: sheetStyles.headerStyleGrey},
        {b: "INSTRUMENT TYPE", style: sheetStyles.headerStyleGrey},
        {c: "GRANTOR", style: sheetStyles.headerStyleGrey},
        {d: "GRANTEE", style: sheetStyles.headerStyleGrey},
        {e: "REC. DATE", style: sheetStyles.headerStyleGrey},
        {f: "EFFECTIVE DATE", style: sheetStyles.headerStyleGrey},
        {g: "DOCUMENT NO.", style: sheetStyles.headerStyleGrey},
        {h: "BOOK", style: sheetStyles.headerStyleGrey},
        {i: "PAGE", style: sheetStyles.headerStyleGrey},
        {j: "DESCRIPTION", style: sheetStyles.headerStyleGrey},
        {k: "WOL", style: sheetStyles.headerStyleGrey},
        {l: "COMMENTS", style: sheetStyles.headerStyleGrey},
    ])


    let listEndIndex = 0
    let docNumber,
        style;
    data.list.map((row: any, index: number) => {
        let verifiedStyle;
        if (row.verified) {verifiedStyle = sheetStyles.textStyle} else {verifiedStyle = sheetStyles.unverified}


        // if (row.downloadURL !== "") {
        //     docNumber = {
        //         text: row.document,
        //         hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        //     }
        //     style = sheetStyles.hyperLinkStyle
        // } else {
        //     docNumber = row.document;
        //     style = sheetStyles.textStyle
        // }
        ///TODO: The above code disables the hyperlink if URL is not present. Disabled at client's request. Code below adds hyperlink regardless.
        docNumber = {
            text: row.document,
            hyperlink: `${storageURL}/Counties/${row.county}/${row.document}.pdf`,
        };
        style = sheetStyles.hyperLinkStyle


        let rowIndex = index + filesEndIndex + 2;
        // let book = checkInput(row.book);
        // let page = checkInput(row.page);
        // let documentNumber = checkInput(row.documentNumber);
        setCells(sheet, rowIndex, [
            {a: docNumber, style: style},
            {b: row.instrument, style: verifiedStyle},
            {c: row.grantor, style: sheetStyles.textStyle},
            {d: row.grantee, style: sheetStyles.textStyle},
            {e: ExcelDateToJSDate(row.fileDate), style: sheetStyles.textStyle},
            {f: ExcelDateToJSDate(row.effectiveDate), style: sheetStyles.textStyle},
            {g: row.reqNumber, style: sheetStyles.textStyle},
            {h: row.book, style: sheetStyles.textStyle},
            {i: row.page, style: sheetStyles.textStyle},
            {j: row.description, style: sheetStyles.textStyle},
            {k: row.wol, style: sheetStyles.textStyle},
            {l: row.comments, style: sheetStyles.textStyle},],)
        listEndIndex = rowIndex
    })

    const lastPage = data.header[0].lastPage ?? ""
    // setCells(sheet, listEndIndex  + 1, [{a: "LAST PAGE: " + lastPage, style: sheetStyles.headerStyle}]);
    setCells(sheet, listEndIndex + 1, [{a: "LIMITATIONS", style: sheetStyles.headerStyleYellowSideBorders}]);
    setCells(sheet, listEndIndex  + 2, [{a: legalDisclaimer, style: sheetStyles.headerStyleYellowSideAndBottomBorders}]);


    setMergesByRow(sheet, [1, 2, 3, 4, 5, 6, 7], ["al"]);
    setMergesByRow(sheet, [listEndIndex  + 1], ["al"]);
    setMergesByRow(sheet, [listEndIndex + 2], ["al"]);
    // setMergesByArea(sheet, [`a${listEndIndex  + 3} l${listEndIndex + 7}`]);

}

const checkClient = (input: any) => {
    if ( input === undefined) {return "None"}
    return input
}



